<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getModelList"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="newContentOnClick"
              >添加</el-button
            >
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="变量">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.variable }}</p>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.remark }}</p>
            </template>
          </el-table-column>
          <el-table-column label="值">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.value }}</p>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
                >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="editModelOnclick(scope.row)"
                type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "webConfigListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
    };
  },
  created() {
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;

      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/web/config/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    newContentOnClick() {
      this.$router.push("webConfig-detail");
    },
    editModelOnclick(model) {
      this.$router.push("webConfig-detail?id=" + model.id);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.removeItem(model);
          _me.$common.removeGlobalStorage("bannerClassList");
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id,
      };
      _me.$common
        .httpPost("/api/web/config/admin/delete", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped>
</style>
