<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                title="返回"
                @back="goBack"
                content="配置详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button
                type="primary"
                icon="el-icon-check"
                @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="edit-form"
          :model="edit_model"
          :rules="rules"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="变量:" prop="variable">
            <el-input
              :disabled="edit_model_id ? true : false"
              v-model="edit_model.variable"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="值:" prop="value">
            <el-input-number
              :min="1"
              v-model="edit_model.value"
              clearable
            ></el-input-number>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              type="textarea"
              v-model="edit_model.remark"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="edit_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:">
            <el-input-number
              v-model="edit_model.number"
              :min="0"
              label="排序"
            ></el-input-number>
          </el-form-item>
          <!-- <CopyPaste
            :button_status="this.edit_model_id !== undefined && this.edit_model_id.length > 0"
            @copyInfo="copyInfo(edit_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste> -->
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// import CopyPaste from "../../../components/CopyPaste.vue";
export default {
  name: "webConfigDetailView",
  //   components: {
  //     CopyPaste,
  //   },
  data() {
    return {
      loading: false,
      rules: {
        variable: [
          { required: true, message: "请输入变量名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
        value: [
          { required: true, message: "请输入变量值", trigger: "blur" },
          {
            min: 1,
            type: "number",
            message: "长度大于 0 个字符",
            trigger: "blur",
          },
        ],
      },
      edit_model_id: "",
      edit_model: {
        id: "",
        variable: "",
        value: "",
        status: 2,
        number: 1,
        remark: "",
      },
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/web/config/admin/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModelOnclick() {
      this.$refs["category_model"].validate((valid) => {
        if (valid) {
          if (
            this.edit_model_id !== undefined &&
            this.edit_model_id.length > 0
          ) {
            this.updateOneModel();
          } else {
            this.addNewModel();
          }
        } else {
          this.$message({
            message: "请检查表单!",
            type: "error",
          });
        }
      });
    },
    addNewModel() {
      let _me = this;
      let params = {
        variable: _me.edit_model.variable,
        value: _me.edit_model.value,
        number: _me.edit_model.number,
        status: _me.edit_model.status,
        remark: _me.edit_model.remark,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/web/config/admin/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        variable: _me.edit_model.variable,
        value: _me.edit_model.value,
        number: _me.edit_model.number,
        status: _me.edit_model.status,
        remark: _me.edit_model.remark,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/web/config/admin/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.edit_model = model;
      this.edit_model.id = this.edit_model_id;
    },
  },
};
</script>

<style scoped>
.edit-form {
  margin: 20px 10px 10px 0;
  text-align: left;
  min-width: 600px;
  max-width: 600px;
}
</style>
